import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { Product } from "../product";
import { EventEmitter, Output } from "@angular/core";
import { UserService } from "../services/user.service";
import { HttpClient } from "@angular/common/http";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CartService {
  @Output() updateCartNumberEvent = new EventEmitter<string>();
  @Output() removeCartEvent = new EventEmitter<string>();
  @Output() placeOrderEvent = new EventEmitter<any>();
  @Output() makePaymentEvent = new EventEmitter<string>();
  @Output() editCartModeEvent = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService

  ) {}

  getCart(): any {
    if (localStorage.quotationList !== undefined) {
      return JSON.parse(localStorage.quotationList);
    } else {
      return [];
    }
  }
  //When user click add cart, all item come to this localstorage and filter later.
  getAllCart(): any {
    if (localStorage.quotationAllList !== undefined) {
      return JSON.parse(localStorage.quotationAllList);
    } else {
      return [];
    }
  }

  //When user click add cart with new user, all item come to this localstorage and show at the account list.
  getNewAccount(): any {
    if (localStorage.newAccountList !== undefined) {
      return JSON.parse(localStorage.newAccountList);
    } else {
      return [];
    }
  }

   //When user click add cart with new project, all item come to this localstorage and show at the project list.
   getNewProject(): any {
    if (localStorage.newProjectList !== undefined) {
      return JSON.parse(localStorage.newProjectList);
    } else {
      return [];
    }
  }

  getSelectedCart(selectedItems: string[]): any {
    let cartItems = this.getCart();
    cartItems = cartItems.filter((each) => {
      return selectedItems.includes(each.cart_id + "");
    });
    return cartItems;
  }

  getCartCount(): number {
    let storage = this.getCart();
    return storage.length;
  }

  updateSubtotal(storage){
    localStorage.quotationList = JSON.stringify(storage);
  }

  getEditCartMode(mode: string): void {
    let bool = mode === "Edit" ? false : true;
    this.editCartModeEvent.emit(bool);
  }

  addCart(quotation: any) {
    let storage = this.getAllCart();
    let count: number = 0;
    let lastIndex: number = 0;

    count = storage.length;
    if (count > 0) lastIndex = storage[count - 1].cart_id;

    let cart_item = {
      cart_id: ++lastIndex,
      cart_detail: quotation,
    };
    storage.push(cart_item);
    count = storage.length;
    //add to the localstorage quatation all.
    localStorage.quotationAllList = JSON.stringify(storage);
    
    //this.updateCartNumberEvent.emit(count + "");

    this.submitLiveCart(storage).subscribe(
			(data) => {
				if (data.status === "OK") {
					console.log('Live cart submit successfully')
				} else {
					console.log(data['result'])
				}
			},
			(error) => {
        console.log('Live cart submit failed')

			}
		);
  }

  removeCart(removeItems: string[]) {
    let cartItems = this.getCart();
    let cartAllItems = this.getAllCart();
    let cartNewProject = this.getNewProject();
    let cartNewAccount = this.getNewAccount();
    let count: number = 0;
    let this_cart_project_id = 0;
    let this_cart_account_id = 0;

    this_cart_project_id = cartItems[0]['cart_detail']['project_details']['project_id'];
    this_cart_account_id = cartItems[0]['cart_detail']['project_details']['account_id'];

    for (let i = 0; i < removeItems.length; i++) {
      cartItems = cartItems.filter((each) => each.cart_id != removeItems[i]);
    }

    for (let i = 0; i < removeItems.length; i++) {
      cartAllItems = cartAllItems.filter((each) => each.cart_id != removeItems[i]);
    }
    count = cartItems.length;

    // setTimeout(() => {
			//delete project if cart is removed (all)
      if(!cartItems || cartItems.length == 0){
        cartNewProject = cartNewProject.filter((each) => each.project_id != this_cart_project_id);
        cartNewAccount = cartNewAccount.filter((each) => each.account_id != this_cart_account_id);
      }

      //if remove cart, both of the quotation alllist and list must same
      localStorage.quotationAllList = JSON.stringify(cartAllItems);
      localStorage.quotationList = JSON.stringify(cartItems);
      localStorage.newProjectList = JSON.stringify(cartNewProject);
      localStorage.newAccountList = JSON.stringify(cartNewAccount);
      this.editCartModeEvent.emit(false);
      //this.updateCartNumberEvent.emit(count + "");
		// }, 200);

    this.submitLiveCart(cartAllItems).subscribe(
			(data) => {
				if (data.status === "OK") {
					console.log('remove cart - Live cart submit successfully')
					console.log(cartAllItems)
				} else {
					console.log(data['result'])
				}
			},
			(error) => {
        console.log('Live cart submit failed')

			}
		);
    
  }

  getCartTotal(selectedItems: string[]): number {
    let cartItems = this.getCart();
    let total = 0;

    cartItems = this.getSelectedCart(selectedItems);

    for (let i = 0; i < cartItems.length; i++) {
      let data = cartItems[i].cart_detail;
      total += data.item.subtotal * data.set_qty;
      for (let j = 0; j < data.addon.length; j++) {
        total += data.addon[j].subtotal * data.set_qty;
      }
    }

    return total;

  }

  getEditCartDetail(cart_id : any) : any{
    let storage = this.getAllCart();
    let tmp = {};
    for(let i = 0 ; i < storage.length ; i++){
      if(cart_id == storage[i].cart_id ){
        tmp = storage[i];
      }
    }
    return tmp;
  }

  makePayment(msg: string) {
    console.log("Make payment is event is Called @@~");
    this.makePaymentEvent.emit(msg);
  }

  // checkout(cart){
  //   this.checkOutEvent.emit(cart);
  // }


  //if new account fill, will save to a tmp localstorage.
  addNewAccount(quotation: any) {
    let storage = this.getNewAccount();
    let count: number = 0;
    let lastIndex: number = 0;
  
    count = storage.length;
    if (count > 0) lastIndex = storage[count - 1].id;

      let tmpdata = quotation;
      let tmp = {
        id: ++lastIndex,
        account_id: tmpdata.account_id,
        addr1: tmpdata.account_addr1,
        addr2: tmpdata.account_addr2,
        city: tmpdata.account_city,
        country: tmpdata.account_country,
        email: tmpdata.account_email,
        name: tmpdata.account_name,
        postcode: tmpdata.account_postcode,
        state: tmpdata.account_state,
        tel: tmpdata.account_contact1,
        pic_name: tmpdata.pic_name,
        company_address: tmpdata.account_company_address,
      }
    
    storage.push(tmp);
    count = storage.length;
    localStorage.newAccountList = JSON.stringify(storage);
  }

  addNewProject(quotation: any) {
    let storage = this.getNewProject();
    let count: number = 0;
    let lastIndex: number = 0;
  
    count = storage.length;
    if (count > 0) lastIndex = storage[count - 1].id;
    
      let tmpdata = quotation;
      let tmp = {
        id: ++lastIndex,
        project_id : tmpdata.project_id,
        title : tmpdata.project_title,
      }
    
    storage.push(tmp);
    count = storage.length;
    localStorage.newProjectList = JSON.stringify(storage);
  }

  //load at the cart page, let the filter cart store in quatationList
  filterCart(account_id:any , project_id: any) {
    let storage = this.getAllCart();
    let count: number = 0;
    let lastIndex: number = 0;

    let tmp_array = [];

    for(let i = 0 ; i < storage.length ; i++){
      let tmp = storage[i]['cart_detail']['project_details'];

      if(account_id == tmp.account_id && project_id == tmp.project_id){
        tmp_array.push(storage[i]);
      }
    }
  
    localStorage.quotationList = JSON.stringify(tmp_array);
  }

  //After submit the quotation, the new id must update.
  updateAccountList(old_id : any, new_id: any) {
    let accountItems = this.getNewAccount();
    let allcartItems = this.getAllCart();
    let count: number = 0;

    //Update the tmp account_id with new account_id
    for(let i=0;i<allcartItems.length;i++){
      let tmp = allcartItems[i]['cart_detail']['project_details'];
      if(tmp.account_id == old_id){
        tmp.account_id = String(new_id);
      }
      allcartItems[i]['cart_detail']['project_details'] = tmp;
    }

    //New account will be remove from newAccountList localstorage.
    accountItems = accountItems.filter((each) => each.account_id != old_id);

    count = accountItems.length;
    localStorage.newAccountList = JSON.stringify(accountItems);
    localStorage.quotationAllList = JSON.stringify(allcartItems);

  }

  updateProjectList(old_id : any, new_id: any) {
    let projectItems = this.getNewProject();
    let allcartItems = this.getAllCart();
    let count: number = 0;

    for(let i=0;i<allcartItems.length;i++){
      let tmp = allcartItems[i]['cart_detail']['project_details'];
      if(tmp.project_id == old_id){
        tmp.project_id = String(new_id);
      }
      allcartItems[i]['cart_detail']['project_details'] = tmp;
    }

    projectItems = projectItems.filter((each) => each.project_id != old_id);
  
    count = projectItems.length;
    localStorage.newProjectList = JSON.stringify(projectItems);
    localStorage.quotationAllList = JSON.stringify(allcartItems);

  }

  submit_editCartDetail(quotationlist, cart_id){

    let storage_all = this.getAllCart();
    let storage = this.getCart();
    
    let tmp_array_all = [];
    let tmp_array = [];

    //for quotationAllList update
    for(let i = 0 ; i < storage_all.length ; i++){
      let tmp_all = storage_all[i];

      if(tmp_all.cart_id == cart_id){
        tmp_all.cart_detail['addon'] = quotationlist.addon;
        tmp_all.cart_detail['item'] = quotationlist.item;
      }
      tmp_array_all.push(tmp_all);
    }

    //for quotationList update
    for(let i = 0 ; i < storage.length ; i++){
      let tmp = storage[i];

      if(tmp.cart_id == cart_id){
        tmp.cart_detail['addon'] = quotationlist.addon;
        tmp.cart_detail['item'] = quotationlist.item;
      }
      tmp_array.push(tmp);
    }

    localStorage.quotationAllList = JSON.stringify(tmp_array_all);
    localStorage.quotationList = JSON.stringify(tmp_array);

    this.submitLiveCart(tmp_array_all).subscribe(
			(data) => {
				if (data.status === "OK") {
					console.log('Live cart submit successfully')
				} else {
					console.log(data['result'])
				}
			},
			(error) => {
        console.log('Live cart submit failed')

			}
		);
  }

  getCheckInId(project_id){

    let storage_all = this.getAllCart();

    console.log('storage_all',storage_all);

    var checkin_id = 0;
    if(storage_all.length > 0){
      for(let x in storage_all){
        if(storage_all[x].cart_detail.project_details.project_id == project_id){
          checkin_id = storage_all[x].cart_detail.project_details.checkin_id;
        }
      }
    }
    
    return checkin_id;
  }

  submitLiveCart(cartList): Observable<any> {
    var data = {
      'token': localStorage.token,
      'user_id': localStorage.user_id,
      'cart': cartList,
    };
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/submitLiveCart`,
        data,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  switchAccountLiveCart(new_user_id,old_user_id): Observable<any> {
    var data = {
      'token': localStorage.token,
      'new_user_id': new_user_id,
      'old_user_id': old_user_id,
    };
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/switchAccountLiveCart`,
        data,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  convertWidthAndHeight(width, height){
    const FEET_PER_MM = 304.8;
			
    // 将宽度和高度从毫米转换为英尺，并取到小数点后三位
    let widthInFeet = +(width / FEET_PER_MM).toFixed(3);
    let heightInFeet = +(height / FEET_PER_MM).toFixed(3);
    
    // 根据规则四舍五入宽度和高度
    widthInFeet = Math.round(widthInFeet * 100) / 100;
    heightInFeet = Math.round(heightInFeet * 100) / 100;

    // 对宽度进行进位处理
    var widthInFeet_tmp = Math.round( (widthInFeet % 1)  * 100) / 100;
    if (widthInFeet_tmp <= 0.19) {
      widthInFeet = Math.floor(widthInFeet);
    } else if (widthInFeet_tmp <= 0.5 && widthInFeet_tmp > 0.19) {
      widthInFeet = Math.floor(widthInFeet) + 0.5;
    } else if (widthInFeet_tmp > 0.5 && widthInFeet_tmp <= 0.69) {
      widthInFeet = Math.floor(widthInFeet) + 0.5;
    } else {
      widthInFeet = Math.ceil(widthInFeet);
    }
    
    // 对高度进行进位处理
    var heightInFeet_tmp = Math.round( (heightInFeet % 1)  * 100) / 100;
    if (heightInFeet_tmp <= 0.19) {
      heightInFeet = Math.floor(heightInFeet);
    } else if (heightInFeet_tmp <= 0.5 && heightInFeet_tmp > 0.19) {
      heightInFeet = Math.floor(heightInFeet) + 0.5;
    } else if (heightInFeet_tmp > 0.5 && heightInFeet_tmp <= 0.69) {
      heightInFeet = Math.floor(heightInFeet) + 0.5;
    } else {
      heightInFeet = Math.ceil(heightInFeet);
    }
    
    return {
      width: widthInFeet,
      height: heightInFeet,
    };
  }



}
