import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";

@Injectable({
  providedIn: "root",
})
export class QuotationService {
  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  submit(quotation): Observable<any> {
    quotation["token"] = localStorage.token;
    quotation["user_id"] = localStorage.user_id;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appQuotationSubmit`,
        quotation,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getList(query: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetQuotationList/${localStorage.user_id}/${localStorage.token}?q=${query}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDetail(quotation_id: string): Observable<any> {
    return (
      this.http
        .get<API_Response>(
          `${baseURL}${langu}/api/appGetQuotationDetail/${localStorage.user_id}/${localStorage.token}?quotation_id=${quotation_id}`
        )
        // .pipe(map((res) => res.result))
        .pipe(catchError(this.processHTTPMsgService.handleError))
    );
  }

  sendMail(quotation_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/quotationMail/${quotation_id}/${localStorage.token}/all`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getQuotationByContactID(contact_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetQuotationByContactID/${localStorage.user_id}/${localStorage.token}?contact_id=${contact_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  addQuotationItem(new_item: any) {
    let storage = [];
    if (localStorage.tmp !== undefined) {
      storage = JSON.parse(localStorage.tmp);
    }
    storage.push(new_item);
    localStorage.tmp = JSON.stringify(storage);
  }

  getQuotationByAgentID(agent_id: string, start_date: string = "", end_date: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetQuotationList/${localStorage.user_id}/${localStorage.token}?agent_id=${agent_id}&start_date=${start_date}&end_date=${end_date}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getQuotationByAccountID(account_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetQuotationList/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getQuotationRevisedDetail(quotation_id: string): Observable<any> {
    return (
      this.http
        .get<API_Response>(
          `${baseURL}${langu}/api/appGetQuotationRevisedDetail/${localStorage.user_id}/${localStorage.token}?quotation_id=${quotation_id}`
        )
        // .pipe(map((res) => res.result))
        .pipe(catchError(this.processHTTPMsgService.handleError))
    );
  }

  printDoc(quotation_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/quotation/appSaveDoc/${quotation_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}
