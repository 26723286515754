import { HttpHeaders } from "@angular/common/http";

// export const baseURL = "http://ctalum.local/";
export const baseURL = "https://ctalum.aipipis.com/";

export const token = localStorage.token;
export const user_id = localStorage.user_id;
export const langu = localStorage.default_lang || "en";
export const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

export interface API_Response {
  comment: string;
  status: string;
  duration: number;
  result: any;
}
