import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  HttpClientModule,
  HttpHeaders,
  HttpErrorResponse,
  HttpRequest,
  HttpClient,
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { UserService } from "./services/user.service";
import { CategoryService } from "./services/category.service";
import { ReactiveFormsModule } from "@angular/forms";
import { QuotationService } from "./services/quotation.service";
import { baseURL } from "./shared/config";
import { SocialSharing } from "@ionic-native/social-sharing";
import { CustomerService } from "./services/customer.service";
import { ProjectService } from "./services/project.service";
import { CartService } from "./services/cart.service";
import { PackageService } from "./services/package.service";
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      // defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient],
      },
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    InAppBrowser,
    SocialSharing,
    UserService,
    QuotationService,
    CategoryService,
    CustomerService,
    ProjectService,
    PackageService,
    CartService,
    Badge,
    { provide: "BaseURL", useValue: baseURL },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
