import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { CatProdBox } from "../shared/cat-prod-box";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { Item } from "../shared/item";
import { ProcessHttpmsgService } from "./process-httpmsg.service";
@Injectable({
  providedIn: "root",
})
export class CustomerService {
  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  // getList(query: string = ""): Observable<any> {
  //   return this.http
  //     .get<API_Response>(
  //       `${baseURL}${langu}/api/appGetContact/${this.user_id}/${this.token}?q=${query}`
  //     )
  //     .pipe(catchError(this.processHTTPMsgService.handleError));
  // }

  getDetail(account_id: string): Observable<any> {
    return (
      this.http
        .get<API_Response>(
          `${baseURL}${langu}/api/appGetAccountDetail/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}`
        )
        // .pipe(map((res) => res.result))
        .pipe(catchError(this.processHTTPMsgService.handleError))
    );
  }

  getAccountList(query: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetAccountList/${localStorage.user_id}/${localStorage.token}?q=${query}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getContactList(account_id: string, query: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetContactList/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}&q=${query}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getAccountByID(account_id: string) {
    return this.getAccountList().pipe(
      map((accounts) =>
        accounts.result.accountList.filter((each) => each.account_id === account_id)
      )
    );
  }

  getContactByID(account_id: string, contact_id: string) {
    return this.getContactList(account_id).pipe(
      map((contacts) =>
        contacts.result.filter((each) => each.contact_id === contact_id)
      )
    );
  }

  getContactByPhone(phoneNum: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetContactList/${localStorage.user_id}/${localStorage.token}?phone=${phoneNum}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getAccountTitle(account_id: string): Observable<string[]> {
    return this.getAccountList()
      .pipe(
        map((res) => {
          return (res = res.result
            .filter((each) => {
              // console.log(each);
              return each.account_id === account_id;
            })
            .map((each) => each.name));

          // res = res.result;
          // console.log(res);
        })
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getContactListByProjectID(project_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetAccountList/${localStorage.user_id}/${localStorage.token}?project_id=${project_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getAccountListByProjectID(project_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetContactList/${localStorage.user_id}/${localStorage.token}?project_id=${project_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  // getDetail(contact_id: string, item_id: string): Observable<any> {
  //   return this.http
  //     .get<API_Response>(
  //       `${baseURL}${langu}/api/appGetContact/${this.user_id}/${this.token}?category_id=${category_id}&item_id=${item_id}`
  //     )
  //     .pipe(map((res) => res.result))
  //     .pipe(catchError(this.processHTTPMsgService.handleError));
  // }

  getAccountListByAgentID(agent_id: string, q='', archived_status=''): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetAccountList/${localStorage.user_id}/${localStorage.token}?agent_id=${agent_id}&q=${q}&archived_status=${archived_status}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  addCustomer(form): Observable<any> {
    form["token"] = localStorage.token;
    form["user_id"] = localStorage.user_id;
    form["account_status"] = 1;
    form["contact_status"] = 1;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appCustomerSubmit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  editCustomer(form): Observable<any> {
    form["token"] = localStorage.token;
    form["user_id"] = localStorage.user_id;
    form["account_status"] = 1;
    form["contact_status"] = 1;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appCustomerEdit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  addContact(form): Observable<any> {
    form["token"] = localStorage.token;
    form["user_id"] = localStorage.user_id;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appContactSubmit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getAssignList(account_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetAssignList/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  appGetAssignList2ndLevel(agent_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetAssignList2ndLevel/${localStorage.user_id}/${localStorage.token}?agent_id=${agent_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  appUpdateAssignSubmit(form): Observable<any> {
    form["token"] = localStorage.token;
    form["user_id"] = localStorage.user_id;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appUpdateAssignSubmit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  appAssignSubmit(form): Observable<any> {
    form["token"] = localStorage.token;
    // form["user_id"] = localStorage.user_id;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appAssignSubmit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  addComment(form): Observable<any> {
    form["token"] = localStorage.token;
    form["user_id"] = localStorage.user_id;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appCustomerCommentSubmit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getNormalCentralRelation(account_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetNormalCentralRelation/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getCompanyList(query: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetCompanyList/${localStorage.user_id}/${localStorage.token}?q=${query}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  checkPhoneNo(account_id: string, tel: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appCheckPhoneNo/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}&tel=${tel}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

}
