import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { CatProdBox } from "../shared/cat-prod-box";
import { CatAlbumBox } from "../shared/cat-album-box";
import { CatAlbumDetailBox } from "../shared/cat-album-detail-box";
// import { Category } from "../shared/category";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";
@Injectable({
  providedIn: "root",
})
export class CategoryService {
  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  getList(query: string = "", parent_id:string = ""): Observable<CatProdBox[]> {
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetCategoryList/${tmp_user_id}/${tmp_token}?q=${query}&id=${parent_id}`
      )
      .pipe(
        map((res) =>
          res.result.categoryList.map((each) => {
            return {
              id: each.id,
              title: each.title,
              thumbnail: each.thumbnail,
              route: each.route
            };
          })
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getCategoryTitle(category_id: string): Observable<string[]> {
    return this.getList()
      .pipe(
        map((res) =>
          res
            .filter((each) => {
              return each.id == category_id;
            })
            .map((each) => each.title)
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getParent(query: string = ""): Observable<CatProdBox[]> {
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetCategoryParent/${tmp_user_id}/${tmp_token}`
      )
      .pipe(
        map((res) =>
          res.result.categoryList.map((each) => {
            return {
              id: each.id,
              title: each.title,
              thumbnail: each.thumbnail,
              route: each.route,
            };
          })
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getAlbumList(category_id:string = "",query: string = ""): Observable<CatAlbumBox[]> {
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetCategoryAlbumList/${tmp_user_id}/${tmp_token}?q=${query}&id=${category_id}`
      )
      .pipe(
        map((res) =>
          res.result.categoryAlbumList.map((each) => {
            return {
              id: each.id,
              title: each.title,
              thumbnail: each.thumbnail,
              route: each.route,
            };
          })
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getAlbumDetailList(album_id:string = ""): Observable<CatAlbumDetailBox[]>{
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetCategoryAlbumDetailAllList/${tmp_user_id}/${tmp_token}?id=${album_id}`
      )
      .pipe(
        map((res) =>
          res.result.categoryAlbumDetailAllList.map((each) => {
            return {
              id: each.id,
              title: each.title,
              type: each.type,
              description: each.description,
              link: each.link,
              thumbnail: each.thumbnail,
            };
          })
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));

  }

  getBannerList(banner_id:string = ""): any {
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetBannerList/${tmp_user_id}/${tmp_token}?id=${banner_id}`
      )
      .pipe(
        map((res) =>
          res.result.bannerList.map((each) => {
            return {
              id: each.id,
              title: each.title,
              thumbnail: each.thumbnail,
              route: each.route,
              description: each.description
            };
          })
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  
}
