import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { CatProdBox } from "../shared/cat-prod-box";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";
@Injectable({
  providedIn: "root",
})
export class ProjectService {
  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  // getList(query: string = ""): Observable<any> {

  getList(query: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetProjectList/${localStorage.user_id}/${localStorage.token}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getProjectByAccountID(account_id: string) {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetProjectList/${localStorage.user_id}/${localStorage.token}?account_id=${account_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  // getContactList(account_id: string, query: string = ""): Observable<any> {
  //   return this.http
  //     .get<API_Response>(
  //       `${baseURL}${langu}/api/appGetContactList/${this.user_id}/${this.token}/${account_id}?q=${query}`
  //     )
  //     .pipe(catchError(this.processHTTPMsgService.handleError));
  // }

  // getContactByID(account_id: string, contact_id: string) {
  //   return this.getContactList(account_id).pipe(
  //     map((contacts) =>
  //       contacts.result.filter((each) => each.contact_id === contact_id)
  //     )
  //   );
  // }

  // getContactByPhone(phoneNum: string): Observable<any> {
  //   return this.http
  //     .get<API_Response>(
  //       `${baseURL}${langu}/api/appGetContactByPhone/${this.user_id}/${this.token}?phone=${phoneNum}`
  //     )
  //     .pipe(catchError(this.processHTTPMsgService.handleError));
  // }

  // getAccountTitle(account_id: string): Observable<string[]> {
  //   return this.getAccountList()
  //     .pipe(
  //       map((res) => {
  //         return (res = res.result
  //           .filter((each) => {
  //             // console.log(each);
  //             return each.account_id === account_id;
  //           })
  //           .map((each) => each.name));

  //         // res = res.result;
  //         // console.log(res);
  //       })
  //     )
  //     .pipe(catchError(this.processHTTPMsgService.handleError));
  // }

  // getDetail(contact_id: string, item_id: string): Observable<any> {
  //   return this.http
  //     .get<API_Response>(
  //       `${baseURL}${langu}/api/appGetContact/${this.user_id}/${this.token}?category_id=${category_id}&item_id=${item_id}`
  //     )
  //     .pipe(map((res) => res.result))
  //     .pipe(catchError(this.processHTTPMsgService.handleError));
  // }
}
