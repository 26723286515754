import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {
	AlertController,
	LoadingController,
	ActionSheetController,
} from "@ionic/angular";
import { Platform } from "@ionic/angular";
import {
	HttpClient,
	HttpHeaders,
	HttpErrorResponse,
	HttpRequest,
} from "@angular/common/http";
import { Httprequest } from "./models/httprequest";
import { TranslateService } from "@ngx-translate/core";
import { Push, PushObject, PushOptions } from "@ionic-native/push";
import { Device } from "@ionic-native/device";
import { UserService } from "./services/user.service";
import { InboxService } from "./services/inbox.service";
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { CartService } from "./services/cart.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
	providers: [Push, Device, Badge],
})
export class AppComponent {
	defLang = "";
	defAccountType = "";
	isLogin = false;
	appConfig = {
		api_url: "https://ctalum.aipipis.com",
	    // api_url: "http://ctalum.local",
		// "api_url": "http://ctalum.local:8080",
		version: "0.0.71",
	};

	api_url = this.appConfig.api_url;
	userdata = {
		name: localStorage.name != null ? localStorage.name : "",
		avatar:
			localStorage.avatar != null
				? localStorage.avatar
				: "https://kalyagen.aipipis.com/assets/img/user-icon.svg",
		email_verified:
			localStorage.email_verified != null ? localStorage.email_verified : "",
		phone_verified:
			localStorage.phone_verified != null ? localStorage.phone_verified : "",
		join_date: localStorage.join_date != null ? localStorage.join_date : "",
		// user_type: localStorage.user_type != null ? localStorage.user_type : "",
	};

	user_type = localStorage.user_type;
	logout_account_id : any [];
	inbox_count = 0;

	public appPages = [
		{
			title: "Product List",
			url: "/category",
			icon: "home",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: "true",
		},
		{
			title: "My Quotation",
			url: "/quotationlist",
			icon: "megaphone",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: "true",
		},
		{
			title: "My Sales Order",
			url: "/esaleskit",
			icon: "briefcase",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: "true",
		},
		{
			title: "My Customer",
			url: "/accountlist",
			icon: "receipt",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: "true",
		},
		{
			title: "My Team",
			url: "/my-team",
			icon: "people",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: this.user_type == '2'||this.user_type == '5',
		},
		{
			title: "Commission Report",
			url: "/commission",
			icon: "bar-chart",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: "true",
		},
		{
			title: "Inbox ("+this.inbox_count+")",
			url: "/inbox",
			icon: "receipt",
			iconimg: "../assets/home-icon.svg",
			iconimgactive: "../assets/home-active-icon.svg",
			ngif: "true",
		},
		{
			title: "Profile",
			url: "/profile",
			icon: "person",
			iconimg: "../assets/user-icon.svg",
			iconimgactive: "../assets/user-active-icon.svg",
			ngif: "true",
		},
		// {
		//   title: "Notification",
		//   url: "/notification",
		//   icon: "notifications",
		//   iconimg: "../assets/home-icon.svg",
		//   iconimgactive: "../assets/home-active-icon.svg",
		// },
		// {
		//   title: "Autoship Subscription",
		//   url: "/autoship-subscription",
		//   icon: "sync",
		//   iconimg: "../assets/home-icon.svg",
		//   iconimgactive: "../assets/home-active-icon.svg",
		// },
		// {
		//   title: "Profile",
		//   url: "/profile",
		//   icon: "person",
		//   iconimg: "../assets/user-icon.svg",
		//   iconimgactive: "../assets/user-active-icon.svg",
		// },
		// {
		//   title: "Settings",
		//   url: "/setting",
		//   icon: "cog",
		//   iconimg: "../assets/setting-icon.svg",
		//   iconimgactive: "../assets/setting-active-icon.svg",
		// },
	];

	pushEnabled = false;
	msgNumber = 0;
	interval= null;

	constructor(
		private router: Router,
		public httpClient: HttpClient,
		public alertController: AlertController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public translate: TranslateService,
		private push: Push,
		private device: Device,
		private platform: Platform,
		private userService: UserService,
		public inboxService: InboxService,
		private badge: Badge,
		public cartService: CartService,
	) {
		// translate.addLangs(['en','zh','ms']);
		if (localStorage.default_lang != null) {
			// this.defLang = localStorage.default_lang;
			this.defLang = "en";
		} else {
			// var setLang = window.navigator.language;
			// var subLang = setLang.substring(2, 0);
			// console.log(subLang);
			// if (subLang != "en" && subLang != "zh" && subLang != "ms") {
				this.defLang = "en";
			// } else {
			// 	this.defLang = subLang;
			// }
		}

		translate.setDefaultLang(this.defLang);
		// translate.setDefaultLang('zh');

		if (localStorage.account_type != null) {
			this.defAccountType = localStorage.account_type;
		}

		this.initializeApp();
		// this.checkMsgIsRead();
	}

	initializeApp() {
		this.checkLoginToken();
		this.interval = setInterval(()=>{
			this.checkLoginToken();
		},70000);
	}

	checkMsgIsRead() {
		if (localStorage.token != null) {
			this.httpClient
				.get(
					this.api_url +
					"/" +
					this.defLang +
					"/api/appCheckNotificationIsRead/" +
					localStorage.login_user_id +
					"/" +
					localStorage.token,
					{ observe: "body" }
				)
				.subscribe(
					(data) => {
						if (data["status"] == "OK") {
							this.msgNumber = data["result"]["totalUnread"];
						} else {
							this.presentAlert_default(
								data["status"],
								data["result"],
								"../../assets/attention2-icon.svg"
							);
						}
					},
					(error) => {
						this.presentAlert_default(
							"ERROR",
							"Connection Error - check notification is read",
							"../../assets/attention2-icon.svg"
						);
					}
				);
		}
	}

	async onNotificationHandler(notification) {
		// this.presentAlert_default(notification["title"],notification["message"]);

		const alert = await this.alertController.create({
			cssClass: "my-custom-class alearboxclass",
			header: "",
			subHeader: "",
			message:
				// '<img src="' + iconimg +'">' +
				"<h2>" +
				notification["title"] +
				"</h2><p>" +
				notification["message"] +
				"</p>",
			backdropDismiss: false,
			buttons: [
				{
					text: "Close",
					cssClass: "confirmbutton",
					handler: () => {
						//update is_read
						// this.markNotificatonAsRead(
						// 	notification["additionalData"]["itemID"]
						// );
					},
				},
			],
		});

		await alert.present();
	}

	markNotificatonAsRead(itemID) {
		this.httpClient
			.get(
				this.api_url +
				"/" +
				this.defLang +
				"/api/appMarkNotificationIsRead/" +
				localStorage.user_id +
				"/" +
				localStorage.token +
				"/" +
				itemID,
				{ observe: "body" }
			)
			.subscribe(
				(data) => {
					if (data["status"] == "OK") {
						this.msgNumber = data["result"]["totalUnread"];
					} else {
						this.presentAlert_default(
							data["status"],
							data["result"],
							"../../assets/attention2-icon.svg"
						);
					}
				},
				(error) => {
					this.presentAlert_default(
						"ERROR",
						"Connection Error - mark notification as read",
						"../../assets/attention2-icon.svg"
					);
				}
			);
	}

	public async configurePushRegister() {
		this.platform.ready().then(() => {
			console.log("the platform is ready");

			if ( (this.device.platform == "Android" || this.device.platform == "iOS") && this.device.manufacturer != "HUAWEI") {
				// to check if we have permission
				this.push.hasPermission().then((res: any) => {
					if (res.isEnabled) {
						console.log("We have permission to send push notifications");
						this.pushEnabled = true;
					} else {
						console.log("We do not have permission to send push notifications");
						this.pushEnabled = false;
					}
				});

				// to initialize push notifications
				const options: PushOptions = {
					android: {},
					ios: {
						alert: "true",
						badge: true,
						sound: "false",
					},
					windows: {},
					browser: {
						pushServiceURL: "http://push.api.phonegap.com/v1/push",
					},
				};

				const pushObject: PushObject = this.push.init(options);

				pushObject.on("notification").subscribe((notification: any) => {
					console.log("Received a notification", notification);
					this.onNotificationHandler(notification);
				});

				pushObject.on("registration").subscribe((registration: any) => {
					console.log("Device registered", registration);
					localStorage.pushRegisterTkn = registration.registrationId;
					localStorage.pushRegisterType = registration.registrationType;

					//insert/update database
					var tknLength = registration.registrationId.length;
					if (tknLength > 0) {
						if (
							localStorage.user_id != null &&
							localStorage.user_id > 0
						) {
							this.updatePushNotificationUser(
								registration.registrationId,
								registration.registrationType,
								localStorage.user_id
							);
						} else {
							//insert with userId 0
							this.updatePushNotificationUser(
								registration.registrationId,
								registration.registrationType,
								"0"
							);
						}
					}
				});

				pushObject.on("error").subscribe((error) => {
					console.error("Error with Push plugin", error);
					this.presentAlert_default("Error with Push plugin", error);
				});
			}
		});
	}

	updatePushNotificationUser(pushToken, pushType, login_user_id) {
		console.log('trigger updatePushNotificationUser')
		this.httpClient
			.post(
				this.api_url +
				"/" +
				this.defLang +
				"/api/appUpdatePushNotificationUser",
				{
					pushToken: pushToken,
					pushType: pushType,
					device_manufacturer: this.device.manufacturer,
					device_id: this.device.uuid,
					device_model: this.device.model,
					device_platform: this.device.platform,
					pushEnabled: this.pushEnabled,
					login_user_id: login_user_id,
				},
				{ observe: "body" }
			)
			.subscribe(
				(data) => {
					if (data["status"] == "OK") {
						localStorage.pushNote = data["result"]["data"]["push_enabled"];
					} else {
						this.presentAlert_default(
							data["status"],
							data["result"],
							"../../assets/attention-icon.svg"
						);
					}
				},
				(error) => {
					this.presentAlert_default(
						"ERROR",
						"Connection Error - update push notification user",
						"../../assets/attention-icon.svg"
					);
				}
			);
	}

	public checkLoginToken = function () {
		this.checkCompanyData();

		if (localStorage.token != null) {
			console.log("Token is exits, check login token");
			//check token is valid or not

			this.userService.checkLoginToken().subscribe(
				(res) => {
					if (res.result.is_login == true) {
						console.log("Verified, can proceed");
						this.isLogin = true;
						localStorage.isLogin = 1;
						this.countInboxUnread();
					} else {
						console.log("Invalid token");
						//localStorage.clear();
						this.isLogin = false;
						localStorage.isLogin = 0;
						// this.router.navigate(["login"]);
						this.set_appPages();

					}
				},
				(error) => {
					console.log("Invalid token");
					//localStorage.clear();
					this.isLogin = false;
					localStorage.isLogin = 0;
					// this.router.navigate(["login"]);
					this.set_appPages();

				}
			);
		} else {
			console.log("Invalid token");
			//localStorage.clear();
			this.isLogin = false;
			localStorage.isLogin = 0;
			// this.router.navigate(["login"]);
			this.set_appPages();

		}
	};

	public checkCompanyData = function () {
		this.userService.checkCompanyData().subscribe(
			(res) => {
				if (res.result.company_hp) {
					localStorage.company_hp = res.result.company_hp;
				} else {
					localStorage.company_hp = '';
				}
			},
			(error) => {
				console.log("Invalid token");
			}
		);
	};

	toLogin() {
		this.router.navigate(["login"]);
	}

	toLogout() {

		this.cartService.submitLiveCart([]).subscribe(
			(data) => {
				if (data.status === "OK") {
					console.log('Live cart submit successfully')
				} else {
					console.log(data['result'])
				}
			},
			(error) => {
        		console.log('Live cart submit failed')
			}
		);

		if(localStorage.remember_me == "true"){
			this.savetheLogoutAccount();
		}

		Object.keys(localStorage).forEach((key) => {
			if(key != "logout_account_id"){
				localStorage.removeItem(key);
			}
		});
		this.router.navigate(["/login"]);

	}

	getThelogoutAccount(){
		if (localStorage.logout_account_id !== undefined) {
		  return JSON.parse(localStorage.logout_account_id);
		} else {
		  return [];
		}
	}

	savetheLogoutAccount(){

		this.isLogin = false;
		localStorage.isLogin = 0;
		let storage = this.getThelogoutAccount();
		storage.push(localStorage.user_id);
		storage = this.removeDuplicates(storage);

		localStorage.logout_account_id = JSON.stringify(storage);
		
	}

	removeDuplicates(data){
		return [...new Set(data)];
	  }

	setLoginStatus(status) {
		this.isLogin = status;
	}

	setUserData(name, avatar, email_verified, phone_verified, join_date) {
		this.userdata = {
			name: name,
			avatar: avatar,
			email_verified: email_verified,
			phone_verified: phone_verified,
			join_date: join_date,
		};

		if (localStorage.account_type != null) {
			this.defAccountType = localStorage.account_type;
		}

		this.set_appPages();

	}

	public async presentLoading(timer=500) {
		const loading = await this.loadingController.create({
			duration: timer,
		});
		console.log("Loading show");
		await loading.present();
	}

	public async stopLoading(countdownTime) {
		setTimeout(() => {
			console.log("Loading hide");
			this.loadingController.dismiss();
		}, countdownTime);
	}

	public async presentAlert_default(title, content, iconimg = "") {
		const alert = await this.alertController.create({
			cssClass: "my-custom-class alearboxclass",
			header: "",
			subHeader: "",
			message:
				'<img src="' +
				iconimg +
				'"><h2>' +
				title +
				"</h2><p>" +
				content +
				"</p>",
			buttons: [
				{
					text: "Close",
					cssClass: "confirmbutton",
					handler: () => { },
				},
			],
		});

		await alert.present();
	}

	public async confirmAction_default(
		title,
		content,
		iconimg = "",
		tobeSubmit,
		url
	) {
		console.log(tobeSubmit);
		const alert = await this.alertController.create({
			cssClass: "my-custom-class alearboxclass confirmActionalearboxclass",
			header: "",
			subHeader: "",
			message:
				'<img src="' +
				iconimg +
				'"><h2>' +
				title +
				"</h2><p>" +
				content +
				"</p>",
			buttons: [
				{
					text: "Cancel",
					cssClass: "colosebutton",

					handler: () => { },
				},
				{
					text: "Confirm",
					cssClass: "confirmbutton",
					handler: () => {
						this.httpClient.post(this.api_url + url, tobeSubmit).subscribe(
							(data: Httprequest) => {
								if (data.status == "OK") {
									location.reload();
								} else {
									this.presentAlert_default(
										data["status"],
										data["result"],
										"../../assets/attention-icon.svg"
									);
								}
							},
							(error) => {
								// this.loadingController.dismiss();
								this.presentAlert_default(
									"ERROR",
									"Connection Error - confirm action default",
									"../../assets/attention-icon.svg"
								);
							}
						);
					},
				},
			],
		});

		await alert.present();
	}

	set_appPages() {
		if (this.isLogin) {
			this.user_type = localStorage.user_type;

			if(this.user_type==0){
				this.appPages = [
					{
						title: "Product List",
						url: "/category",
						icon: "home",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "Inbox ("+this.inbox_count+")",
						url: "/inbox",
						icon: "receipt",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "Profile",
						url: "/profile",
						icon: "person",
						iconimg: "../assets/user-icon.svg",
						iconimgactive: "../assets/user-active-icon.svg",
						ngif: "true",
					},
				];
			} else {
				this.appPages = [
					{
						title: "Product List",
						url: "/category",
						icon: "home",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "My Quotation",
						url: "/quotationlist",
						icon: "megaphone",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "My Sales Order",
						url: "/esaleskit",
						icon: "briefcase",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "My Customer",
						url: "/accountlist",
						icon: "receipt",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "My Team",
						url: "/my-team",
						icon: "people",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: this.user_type == '2'||this.user_type == '5',
					},
					{
						title: "Commission Report",
						url: "/commission",
						icon: "bar-chart",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "Inbox ("+this.inbox_count+")",
						url: "/inbox",
						icon: "receipt",
						iconimg: "../assets/home-icon.svg",
						iconimgactive: "../assets/home-active-icon.svg",
						ngif: "true",
					},
					{
						title: "Profile",
						url: "/profile",
						icon: "person",
						iconimg: "../assets/user-icon.svg",
						iconimgactive: "../assets/user-active-icon.svg",
						ngif: "true",
					},
					// {
					//   title: "Notification",
					//   url: "/notification",
					//   icon: "notifications",
					//   iconimg: "../assets/home-icon.svg",
					//   iconimgactive: "../assets/home-active-icon.svg",
					// },
					// {
					//   title: "Autoship Subscription",
					//   url: "/autoship-subscription",
					//   icon: "sync",
					//   iconimg: "../assets/home-icon.svg",
					//   iconimgactive: "../assets/home-active-icon.svg",
					// },
					// {
					//   title: "Profile",
					//   url: "/profile",
					//   icon: "person",
					//   iconimg: "../assets/user-icon.svg",
					//   iconimgactive: "../assets/user-active-icon.svg",
					// },
					// {
					//   title: "Settings",
					//   url: "/setting",
					//   icon: "cog",
					//   iconimg: "../assets/setting-icon.svg",
					//   iconimgactive: "../assets/setting-active-icon.svg",
					// },
				];
			}
			
		} else {
			this.user_type = localStorage.user_type;

			this.appPages = [
				{
					title: "Product List",
					url: "/category",
					icon: "home",
					iconimg: "../assets/home-icon.svg",
					iconimgactive: "../assets/home-active-icon.svg",
					ngif: "true",
				},
			];
		}
	}

	public async countInboxUnread() {
		this.platform.ready().then(() => {
			console.log("the badge platform is ready");
			this.inboxService.countUnread().subscribe(
				(res) => {
					this.inbox_count = res.result.count;
					this.set_appPages();

					this.badge.hasPermission().then((res: any) => {
						if (res.isEnabled) {
							console.log("Permission has been granted: Badge");
						} else {
							console.log("We do not have permission for Badge");
						}
					});
					
					this.badge.set(this.inbox_count);
					var get_badge = this.badge.get();
					console.log('get_badge',get_badge)
					// this.badge.increase(1);
					// this.badge.clear();
		
				},
				(error) => {
					console.log(error);
				}
				);
			
		});
	}

}
