import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  login(loginCredentail): Observable<any> {
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/login`,
        loginCredentail,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  reset(email): Observable<any> {
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/passwordRecovery`,
        email,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  checkLoginToken(): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/checkLoginToken/${localStorage.user_id}/${localStorage.token}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  checkCompanyData(): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/checkCompanyData`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  setLocalStorage(result) {
    localStorage.user_id = result.user_id;
    localStorage.name = result.name;
    localStorage.avatar = result.avatar;
    localStorage.user_type = result.user_type;
    localStorage.token = result.token;
    localStorage.join_date = result.join_date;
    localStorage.rebate_type = result.rebate_type;
  }

  getList(query: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetUserList/${localStorage.user_id}/${localStorage.token}?q=${query}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDetail(user_id: string, account_id: string): Observable<any> {
    return (
      this.http
        .get<API_Response>(
          `${baseURL}${langu}/api/appGetUserDetail/${localStorage.user_id}/${localStorage.token}?user_id=${user_id}&account_id=${account_id}`
        )
        // .pipe(map((res) => res.result))
        .pipe(catchError(this.processHTTPMsgService.handleError))
    );
  }

  addTeamMember(form): Observable<any> {
    form["token"] = localStorage.token;
    form["user_id"] = localStorage.user_id;
    form["user_type"] = 3;
    form["position"] = 'Agent';
    form["status"] = 1;
    return this.http
      .post<API_Response>(
        `${baseURL}${langu}/api/appTeamMemberSubmit`,
        form,
        httpOptions
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getTeamMemberList(): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetTeamMemberList/${localStorage.user_id}/${localStorage.token}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getUserListByCommission(): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetUserListByCommission/${localStorage.user_id}/${localStorage.token}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  switchAccount(id): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/switchAccount/${id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getUserByAgentID(agent_id: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetUserList/${localStorage.user_id}/${localStorage.token}?agent_id=${agent_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

}
