import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }


  getPackageList(parent_id: string = ""): any{
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetPackageList/${tmp_user_id}/${tmp_token}?q=${parent_id}`
      )
      .pipe(
        map((res) =>
          res.result.packageList.map((each) => {
            return {
              id: each.id,
              title: each.title,
              thumbnail: each.thumbnail,
              parent_id : each.parent_id,
              category_id : each.category_id,
              category_title : each.category_title,
              quotation_id : each.quotation_id,
              amount : each.amount,
              quotation_template_title : each.quotation_template_title,
              quotation_is_template : each.quotation_is_template,
              route: each.route
            };
          })
        )
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }


  getPackageDetail(quotation_id: string): Observable<any> {
    return (
      this.http
        .get<API_Response>(
          `${baseURL}${langu}/api/appGetQuotationPackageDetail/${localStorage.user_id}/${localStorage.token}?quotation_id=${quotation_id}`
        )
        // .pipe(map((res) => res.result))
        .pipe(catchError(this.processHTTPMsgService.handleError))
    );
  }

}
