import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "category",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./terms/terms.module").then((m) => m.TermsPageModule),
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./logout/logout.module").then((m) => m.LogoutPageModule),
  },
  {
    path: "trade",
    loadChildren: () =>
      import("./trade/trade.module").then((m) => m.TradePageModule),
  },
  {
    path: "trade/:voucher_id/:type",
    loadChildren: () =>
      import("./trade/trade.module").then((m) => m.TradePageModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path: "profile/:type",
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfilePageModule),
  },
  {
    path: "setting",
    loadChildren: () =>
      import("./setting/setting.module").then((m) => m.SettingPageModule),
  },
  {
    path: "chathome",
    loadChildren: () =>
      import("./chathome/chathome.module").then((m) => m.ChathomePageModule),
  },
  {
    path: "wishlist-voucher-detail/:restaurant_id/:type",
    loadChildren: () =>
      import("./wishlist-voucher-detail/wishlist-voucher-detail.module").then(
        (m) => m.WishlistVoucherDetailPageModule
      ),
  },
  {
    path: "search",
    loadChildren: () =>
      import("./search/search.module").then((m) => m.SearchPageModule),
  },
  {
    path: "search/:type",
    loadChildren: () =>
      import("./search/search.module").then((m) => m.SearchPageModule),
  },
  {
    path: "restaurant-detail/:restaurant_id",
    loadChildren: () =>
      import("./restaurant-detail/restaurant-detail.module").then(
        (m) => m.RestaurantDetailPageModule
      ),
  },
  {
    path: "chatroom-detail/:id",
    loadChildren: () =>
      import("./chatroom-detail/chatroom-detail.module").then(
        (m) => m.ChatroomDetailPageModule
      ),
  },
  {
    path: "ownerlist/:id/:mode",

    loadChildren: () =>
      import("./ownerlist/ownerlist.module").then((m) => m.OwnerlistPageModule),
  },
  {
    path: "quick-link/:voucher_id",
    loadChildren: () =>
      import("./quick-link/quick-link.module").then(
        (m) => m.QuickLinkPageModule
      ),
  },
  {
    path: "product",
    loadChildren: () =>
      import("./product/product.module").then((m) => m.ProductPageModule),
  },
  {
    path: "product-detail/:item_id",
    loadChildren: () =>
      import("./product-detail/product-detail.module").then(
        (m) => m.ProductDetailPageModule
      ),
  },
  {
    path: "testimonial",
    loadChildren: () =>
      import("./testimonial/testimonial.module").then(
        (m) => m.TestimonialPageModule
      ),
  },
  {
    path: "testimonial-detail/:testimonial_id",
    loadChildren: () =>
      import("./testimonial-detail/testimonial-detail.module").then(
        (m) => m.TestimonialDetailPageModule
      ),
  },
  {
    path: "cart/:account_id",
    loadChildren: () =>
      import("./cart/cart.module").then((m) => m.CartPageModule),
  },
  {
    path: "autoship-subscription",
    loadChildren: () =>
      import("./autoship-subscription/autoship-subscription.module").then(
        (m) => m.AutoshipSubscriptionPageModule
      ),
  },
  {
    path: "testimonial-add",
    loadChildren: () =>
      import("./testimonial-add/testimonial-add.module").then(
        (m) => m.TestimonialAddPageModule
      ),
  },
  {
    path: "announcement",
    loadChildren: () =>
      import("./announcement/announcement.module").then(
        (m) => m.AnnouncementPageModule
      ),
  },
  {
    path: "announcement-detail/:announcement_id",
    loadChildren: () =>
      import("./announcement-detail/announcement-detail.module").then(
        (m) => m.AnnouncementDetailPageModule
      ),
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./checkout/checkout.module").then((m) => m.CheckoutPageModule),
  },
  {
    path: "esaleskit",
    loadChildren: () =>
      import("./esaleskit/esaleskit.module").then((m) => m.EsaleskitPageModule),
  },
  {
    path: "esaleskit-detail/:esaleskit_id",
    loadChildren: () =>
      import("./esaleskit-detail/esaleskit-detail.module").then(
        (m) => m.EsaleskitDetailPageModule
      ),
  },
  {
    path: "esaleskit-add",
    loadChildren: () =>
      import("./esaleskit-add/esaleskit-add.module").then(
        (m) => m.EsaleskitAddPageModule),
  },
  {
    path: "pre-agent-checklist",
    loadChildren: () =>
      import("./pre-agent-checklist/pre-agent-checklist.module").then(
        (m) => m.PreAgentChecklistPageModule
      ),
  },
  {
    path: "order",
    loadChildren: () =>
      import("./order/order.module").then((m) => m.OrderPageModule),
  },
  {
    path: "order-detail/:order_id",
    loadChildren: () =>
      import("./order-detail/order-detail.module").then(
        (m) => m.OrderDetailPageModule
      ),
  },
  {
    path: "my-team",
    loadChildren: () =>
      import("./my-team/my-team.module").then((m) => m.MyTeamPageModule),
  },
  {
    path: "my-team-detail/:account_id",
    loadChildren: () =>
      import("./my-team-detail/my-team-detail.module").then(
        (m) => m.MyTeamDetailPageModule
      ),
  },
  {
    path: "my-team-add",
    loadChildren: () =>
      import("./my-team-add/my-team-add.module").then(
        (m) => m.MyTeamAddPageModule
      ),
  },
  {
    path: "notification",
    loadChildren: () =>
      import("./notification/notification.module").then(
        (m) => m.NotificationPageModule
      ),
  },
  {
    path: "commission",
    loadChildren: () =>
      import("./commission/commission.module").then(
        (m) => m.CommissionPageModule
      ),
  },
  // {
  //   path: "categorylist",
  //   loadChildren: () =>
  //     import("./categorylist/categorylist.module").then(
  //       (m) => m.CategorylistPageModule
  //     ),
  // },
  {
    path: "categorylist/:parent_id",
    loadChildren: () =>
      import("./categorylist/categorylist.module").then(
        (m) => m.CategorylistPageModule
      ),
  },
  {
    path: "category-album/:parent_id",
    loadChildren: () =>
      import("./category-album/category-album.module").then(
        (m) => m.CategoryAlbumPageModule
      ),
  },
  {
    path: "productlist/:category_id",
    loadChildren: () =>
      import("./productlist/productlist.module").then(
        (m) => m.ProductlistPageModule
      ),
  },
  {
    path: "product-detail-modal",
    loadChildren: () =>
      import("./product-detail-modal/product-detail-modal.module").then(
        (m) => m.ProductDetailModalPageModule
      ),
  },
  {
    path: "checkout-complete",
    loadChildren: () =>
      import("./checkout-complete/checkout-complete.module").then(
        (m) => m.CheckoutCompletePageModule
      ),
  },
  {
    path: "quotationlist",
    loadChildren: () =>
      import("./quotationlist/quotationlist.module").then(
        (m) => m.QuotationlistPageModule
      ),
  },
  {
    path: "quotation-detail/:quotation_id",
    loadChildren: () =>
      import("./quotation-detail/quotation-detail.module").then(
        (m) => m.QuotationDetailPageModule
      ),
  },

  {
    path: "accountlist",
    loadChildren: () =>
      import("./accountlist/accountlist.module").then(
        (m) => m.AccountlistPageModule
      ),
  },
  {
    path: "account-add",
    loadChildren: () =>
      import("./account-add/account-add.module").then(
        (m) => m.AccountAddPageModule
      ),
  },
  {
    path: "contactlist/:account_id",
    loadChildren: () =>
      import("./contactlist/contactlist.module").then(
        (m) => m.ContactlistPageModule
      ),
  },
  {
    path: "contact-add",
    loadChildren: () =>
      import("./contact-add/contact-add.module").then(
        (m) => m.ContactAddPageModule
      ),
  },
  {
    path: "contact-detail/:account_id/:contact_id",
    loadChildren: () =>
      import("./contact-detail/contact-detail.module").then(
        (m) => m.ContactDetailPageModule
      ),
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'category-album-modal',
    loadChildren: () => import('./category-album-modal/category-album-modal.module').then( m => m.CategoryAlbumModalPageModule)
  },
  {
    path: 'category-album-image-modal',
    loadChildren: () => import('./category-album-image-modal/category-album-image-modal.module').then( m => m.CategoryAlbumImageModalPageModule)
  },
  {
    path: 'product-detail-cart-modal',
    loadChildren: () => import('./product-detail-cart-modal/product-detail-cart-modal.module').then( m => m.ProductDetailCartModalPageModule)
  },
  {
    path: 'cart-account',
    loadChildren: () => import('./cart-account/cart-account.module').then( m => m.CartAccountPageModule)
  },
  {
    path: 'cart-project/:account_id',
    loadChildren: () => import('./cart-project/cart-project.module').then( m => m.CartProjectPageModule)
  },
  {
    path: 'banner/:banner_id',
    loadChildren: () => import('./banner/banner.module').then( m => m.BannerPageModule)
  },
  {
    path: 'package-child/:parent_id',
    loadChildren: () => import('./package-child/package-child.module').then( m => m.PackageChildPageModule)
  },
  {
    path: 'package-detail/:quotation_id',
    loadChildren: () => import('./package-detail/package-detail.module').then( m => m.PackageDetailPageModule)
  },
  {
    path: 'quotationlist-revised/:quotation_id',
    loadChildren: () => import('./quotationlist-revised/quotationlist-revised.module').then( m => m.QuotationlistRevisedPageModule)
  },
  {
    path: 'product-detail-show-info-modal',
    loadChildren: () => import('./product-detail-show-info-modal/product-detail-show-info-modal.module').then( m => m.ProductDetailShowInfoModalPageModule)
  },
  {
    path: 'assignlist',
    loadChildren: () => import('./assignlist/assignlist.module').then( m => m.AssignlistPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxPageModule)
  },
  {
    path: 'inbox-detail/:inbox_id',
    loadChildren: () => import('./inbox-detail/inbox-detail.module').then( m => m.InboxDetailPageModule)
  },












  // {
  //   path: 'tab1',
  //   loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  // },
  // {
  //   path: 'tab2',
  //   loadChildren: () => import('./tab2/tab2.module').then( m => m.Tab2PageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
