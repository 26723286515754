import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { baseURL, httpOptions, langu, API_Response } from "../shared/config";
import { ProcessHttpmsgService } from "./process-httpmsg.service";

@Injectable({
  providedIn: "root",
})
export class InboxService {
  user_id: string = localStorage.user_id || null;
  token: string = localStorage.token || null;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  getList(start_date: string = "", end_date: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetInboxList/${localStorage.user_id}/${localStorage.token}?start_date=${start_date}&end_date=${end_date}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  appGetCommissionRateByQuotation(quotation_id: string = ""): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appGetCommissionRateByQuotation/${localStorage.user_id}/${localStorage.token}?quotation_id=${quotation_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  printDoc(settlement_report_id: string): Observable<any> {
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/commission/appSaveDoc/${settlement_report_id}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getDetail(inbox_id: string): Observable<any> {
    return (
      this.http
        .get<API_Response>(
          `${baseURL}${langu}/api/appGetInboxDetail/${localStorage.user_id}/${localStorage.token}?inbox_id=${inbox_id}`
        )
        // .pipe(map((res) => res.result))
        .pipe(catchError(this.processHTTPMsgService.handleError))
    );
  }

  countUnread(): Observable<any> {
    var tmp_user_id = 0;
    var tmp_token = 0;
    if(localStorage.user_id){
      tmp_user_id = localStorage.user_id;
    }
    if(localStorage.token){
      tmp_token = localStorage.token;
    }
    return this.http
      .get<API_Response>(
        `${baseURL}${langu}/api/appCountInboxUnread/${tmp_user_id}/${tmp_token}`
      )
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

}